'use strict';

/*
*  Handling anchor tags with role button. Space should trigger action
*/
window.handleButtonKeyPress = function (event) {
  var $ = jQuery.noConflict();

  if (event.keyCode === 32) {
    // Prevent the default action to stop scrolling when space is pressed
    event.preventDefault();
    // Act as native button, trig action when space button pressed
    $(event.target)[0].click();
  }
};

/*
* Script for handling open/close in accordion list
*/
var accordion = function accordion() {
  var $ = jQuery.noConflict();

  // Make all invisible links in closed accordion panels non-focusable from start
  var $hiddenLinks = $('.accordion-List_Element_Panel').find('a');
  $.each($hiddenLinks, function (i, link) {
    $(link).attr('tabindex', -1);
  });

  var $triggers = $('.accordion-List_Element_Trigger');

  $('.accordion-List_Element_Trigger').on('click', function (event) {
    event.preventDefault();
    var $trigger = $(event.currentTarget);
    var $triggedPanel = $trigger.parent('.accordion-List_Element');
    var $triggedPanelContent = $triggedPanel.find('.accordion-List_Element_Panel');
    var $linksInside = $triggedPanelContent.find('a');
    if ($triggedPanel.hasClass('active')) {
      // Panel is open, close it and set any links inside to non-focusable
      $triggedPanel.removeClass('active');
      // Update aria attributes
      $trigger.attr('aria-expanded', false);
      $triggedPanelContent.attr('aria-hidden', true);
      $.each($linksInside, function (i, link) {
        $(link).attr('tabindex', -1);
      });
    } else {
      // Element is closed, close open element if any and open the selected one.
      var $currentActiveElement = $('.accordion-List_Element.active');
      if ($currentActiveElement.length) {
        // Close current open panel
        $currentActiveElement.removeClass('active');
        // Update aria attributes
        $currentActiveElement.find('.accordion-List_Element_Trigger').attr('aria-expanded', false);
        $currentActiveElement.find('.accordion-List_Element_Panel').attr('aria-hidden', true);
        // Set any links inside closing element to non-focusable
        var $linksToDisable = $currentActiveElement.find('.accordion-List_Element_Panel a');
        $.each($linksToDisable, function (i, link) {
          $(link).attr('tabindex', -1);
        });
      }
      // Open selected panel and make all links inside focusable
      $triggedPanel.addClass('active');
      // Update aria attributes
      $trigger.attr('aria-expanded', true);
      $triggedPanelContent.attr('aria-hidden', false);
      $.each($linksInside, function (i, link) {
        $(link).attr('tabindex', 0);
      });
    }
  });

  $('.accordion-List_Element_Trigger').on('keydown', function (event) {
    // Based on https://www.w3.org/TR/wai-aria-practices/examples/accordion/accordion.html

    var $target = $(event.target);
    var key = event.which.toString();
    // 33 = Page Up, 34 = Page Down
    var ctrlModifier = event.ctrlKey && key.match(/33|34/);

    if (key.match(/38|40/) || ctrlModifier) {
      // Arrow up and down operations
      var index = $triggers.index($target);
      var direction = key.match(/34|40/) ? 1 : -1;
      var length = $triggers.length;
      var newIndex = (index + length + direction) % length;

      $triggers.eq(newIndex).focus();

      event.preventDefault();
    } else if (key.match(/35|36/)) {
      // 35 = End, 36 = Home keyboard operations
      switch (key) {
        // Go to first accordion
        case '36':
          $triggers.eq(0).focus();
          break;
        // Go to last accordion
        case '35':
          $triggers.eq($triggers.length - 1).focus();
          break;
      }
      event.preventDefault();
    }
  });
};

/*
* Script for handling menu open/close
*/
var menu = function menu() {
  var $ = jQuery.noConflict();

  $('.header-Navigation_Tools_MenuTrigger').on('click', function (event) {
    event.preventDefault();
    $(event.currentTarget).toggleClass('active');
    var $menu = $('.header-Navigation_Menu');
    var $menuList = $menu.find('a');
    $menu.toggleClass('open');
    if ($menu.hasClass('open')) {
      $.each($menuList, function (i, elm) {
        $(elm).attr('tabindex', 0);
      });
    } else {
      $.each($menuList, function (i, elm) {
        $(elm).attr('tabindex', -1);
      });
    }
  });

  // When scrolling to a section on page, we need to move focus
  // Based on https://css-tricks.com/snippets/jquery/smooth-scrolling/
  $('.header-Menu_Element').on('click', function (event) {
    var theUrl = event.target.href;
    var theHash = theUrl.substring(theUrl.indexOf('#'));
    var $target = $(theHash);
    if ($target.length) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: $target.offset().top
      }, 1000, function () {
        // Callback after animation
        // Must change focus!
        $target.focus();
        if ($target.is(':focus')) {
          // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
          $target.focus(); // Set focus again
        }
      });
    }
  });

  /* Close menu on click outside menu */
  $(document).click(function (event) {
    var $theTarget = $(event.target);
    var $theMenu = $('.header-Navigation_Menu');
    if (!$theTarget.hasClass('header-Navigation_Tools_MenuTrigger') && $theTarget.parents().index($theMenu) === -1 && $theMenu.hasClass('open')) {
      $('.header-Navigation_Tools_MenuTrigger').click();
    }
  });
};

/*
* Script for handling scroll down button
*/
var scrollButton = function scrollButton() {
  var $ = jQuery.noConflict();

  $('.header-Arrow').on('click', function (event) {
    event.preventDefault();
    var contentElm = document.getElementById('content');
    var $target = $(contentElm);
    if ($target.length) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: $target.offset().top
      }, 1000, function () {
        // Callback after animation
        // Must change focus!
        $target.focus();
        if ($target.is(':focus')) {
          // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
          $target.focus(); // Set focus again
        }
      });
    }
  });
};

/*
* Script for handling toggling of cookie explanation
*/

var explanation = function explanation() {
  var $ = jQuery.noConflict();

  $('.js-ExplanationToggler').on('click', function (event) {
    event.preventDefault();
    var $explanation = $('.footer-Explanation');
    $explanation.toggleClass('footer-Explanation-open');
    if ($explanation.hasClass('footer-Explanation-open')) {
      $explanation.attr('aria-expanded', true);
    } else {
      $explanation.attr('aria-expanded', false);
    }
  });
};

$(document).ready(function () {
  menu();
  scrollButton();
  accordion();
  explanation();
});